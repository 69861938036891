<template>
  <div class="content-certificate">
    <h5>
      Parabéns {{ certificate.NameUser }} pela conclusão do curso de
      {{ certificate.NameCurso }}
    </h5>
    <div>
      <q-btn
        unelevated
        no-caps
        color="default-pink"
        :loading="btnLoading"
        size="md"
        label="Baixar certificado"
        class="btn-login"
        @click="getPdf"
      />
    </div>
    <section id="certificate">
      <img class="layout" src="../../assets/images/certificate.png" />

      <p v-show="certificateEnabled" class="message">
        Certificamos que o aluno <b>{{ certificate.NameUser }}</b> concluiu com
        sucesso o curso de <b>{{ certificate.NameCurso }}</b
        >.<br />Essa pessoa foi aprovada no exame de certificação que comprova
        seus conhecimentos sobre o time, ministrado pela
        {{ certificate.NameCompany }}.
      </p>
      <p v-show="certificateEnabled" class="message-date">
        Data: {{ certificate.DateCourse }}
      </p>
    </section>

    <q-inner-loading
        :showing="loading"
        label="Por favor aguarde..."
        label-class="text-teal"
        label-style="font-size: 1.1em"
      >
        <div class="collumn justify-center q-my-md">
          <div style="height: auto; width: 50px; margin: auto">
            <LoadingIcon />
          </div>
          <label>Por favor aguarde...</label>
        </div>
      </q-inner-loading>
  </div>
</template>

<script>
import TrailDataServices from "@/services/TrailDataServices";
import LoadingIcon from "@/components/LoadingIcon.vue";

import { useQuasar } from "quasar";
import { ref, onMounted, reactive } from "vue";
import { useRouter, useRoute } from "vue-router";
import { jsPDF } from "jspdf";

export default {
  name: "Exam",
  components: {
    LoadingIcon,
  },
  setup() {
    const $q = useQuasar();
    let _router = new useRouter();
    let _route = new useRoute();
    let _trailDataServices = new TrailDataServices();
    let _courseId = _route.query.courseId;
    let loading = ref(false);
    let certificateEnabled = ref(false);
    let certificate = reactive({
      DateCourse: "",
      NameCompany: "",
      NameUser: "",
      NameCurso: "",
    });

    async function _getCertification() {
      loading.value = true;
      await _trailDataServices.emitCertificate(_courseId).then((response) => {
        console.log(response.data);
        certificate.NameCurso = response.data.nameCurso;
        certificate.DateCourse = response.data.dateCourse;
        certificateEnabled.value = response.data.certificate_enabled;

        var pendingEvaluations = response.data.pending_evaluations;

        if (!certificateEnabled.value) {
          //  _router.push({ name: "knowledge" });
          //  _alertOf(`Certificado não disponível! Você ficou sem finalizar ${pendingEvaluations} avaliações`);
        }
        certificateEnabled.value = true;
        loading.value = false;
      });
    }

    async function getPdf() {
      const certificatePage = document.getElementById("certificate");

      const pdf = new jsPDF("l", "px", [505, 700]);

      pdf.html(certificatePage, {
        callback: function (doc) {
          doc.save("certificado.pdf");
        },
      });
    }

    function _getNameStudent() {
      let user = JSON.parse(localStorage.getItem("vuex")).userdata;
      certificate.NameUser = user.first_name + " " + user.last_name;
      certificate.NameCompany = user.company_name;
    }

    onMounted(() => {
      if (_courseId == null) {
        _router.push({ name: "knowledge" });
        _alertOf("Parâmetro courseId inválido.");
        return;
      }
      _getCertification();
      _getNameStudent();
    });

    function _alertOf(message, color = "red-9") {
      $q.notify({
        message: message,
        color: color,
        position: "top",
      });
    }

    return {
      loading,
      certificateEnabled,
      certificate,
      getPdf,
    };
  },
};
</script>

<style lang="scss" scoped>
.content-certificate {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;

  h5 {
    width: 60%;
    margin: 0 0 20px 0;
    font-size: 1.5rem;
    font-weight: 700;
    text-align: center;
  }

  section {
    width: 700px;
    height: 600px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
}

.layout {
  width: 100%;
  height: auto;
}
.title {
  position: absolute;
  font-size: 32px;
  top: 21%;
  left: 59%;
  word-break: break-word;
  text-align: end;
  color: #152542;
  font-family: Montserrat !important;
  font-weight: 600;
  margin: 0;
}
.message {
  width: 60%;
  position: absolute;
  font-size: 18px;
  top: 40%;
  word-break: break-word;
  text-align: justify;
  left: 35%;
}
.message-date {
  position: absolute;
  font-size: 18px;
  top: 77%;
  word-break: break-word;
  text-align: justify;
  left: 35%;
}
</style>
